import React, { ReactElement, useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useSelector } from "react-redux";
import { Grid, Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PendingVaccineAppointmentTable from "./PendingVaccineAppointmentTable";
import { DashboardLayout, InputWithIcon, FromSelect, DatePicker } from "../../../components";
import { getPendingVaccineAppointments } from "../../../redux/actionCreators";
import { AppState } from "../../../redux/store";
import { ScheduleAppointmentModel } from "../../../types/models/Provider/ScheduleAppointment";
dayjs.extend(utc);
export const PendingVaccineAppointment = (): ReactElement => {
  const { userData } = useSelector<AppState, AppState["User"]>(({ User }) => User);
  const [pendingVaccineAppointments, setPendingVaccineAppointments] = useState<ScheduleAppointmentModel[]>([]);
  const [searchDate, setSearchDate] = React.useState<dayjs.Dayjs | null>(null);
  const [searchPatient, setSearchPatient] = useState("");
  const [searchStatus, setSearchStatus] = React.useState("");
  const [statusOptions] = React.useState([
    { value: "", label: "None" },
    { value: "Pre-appointment paperwork remaining", label: "Paper work remaining" },
    { value: "Pre-appointment paperwork done", label: "Paper work done" },
    { value: "Verified", label: "Verified" },
  ]);
  const handleChange = (event: any) => {
    if (event.target.name === "patient") {
      setSearchPatient(event.target.value as string);
    }
    if (event.target.name === "status") {
      setSearchStatus(event.target.value as string);
    }
  };
  useEffect(() => {
    const getPendingVaccineAppointmentsData = async () => {
      try {
        let res = await getPendingVaccineAppointments({ biz_branch_id: userData.selected_branch.biz_branch_id });
        setPendingVaccineAppointments(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getPendingVaccineAppointmentsData();
  }, [userData]);
  const filteredPendingVaccineAppointments = pendingVaccineAppointments.filter((pendingAppointment) => {
    // Convert pendingAppointment.date to Dayjs object
    const appointmentDate = dayjs.utc(pendingAppointment.date);
    const dateMatch = !searchDate || appointmentDate.isSame(searchDate, "day");
    const patientNameMatch = pendingAppointment.patient_name.toLowerCase().includes(searchPatient.toLowerCase());
    const statusMatch = pendingAppointment.status.toLowerCase().includes(searchStatus.toLowerCase());
    return dateMatch && patientNameMatch && statusMatch;
  });
  return (
    <DashboardLayout>
      <div>
        <h2 className="text-[24px] text-primary-main font-[600]">Pending Appointments</h2>
      </div>
      <Stack direction="row" justifyContent="space-between" sx={{ mb: { xs: 3, xl: 4 }, mt: { xs: 2, xl: 3 } }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <DatePicker label="Date" value={searchDate} setValue={setSearchDate} />
          </Grid>

          <Grid item xs={6}>
            <InputWithIcon label="Search by patient" name="patient" value={searchPatient} onChange={handleChange} StartIcon={SearchIcon} />
          </Grid>

          <Grid item xs={3}>
            <FromSelect name="status" label="Status" value={searchStatus} onChange={handleChange} options={statusOptions} />
          </Grid>
        </Grid>
      </Stack>

      <PendingVaccineAppointmentTable pendingVaccineAppointments={filteredPendingVaccineAppointments} setPendingVaccineAppointments={setPendingVaccineAppointments} />
    </DashboardLayout>
  );
};
