import { ReactElement } from "react";
interface props {
  fill?: string;
}
export const EditPenIcon: React.FC<props> = ({ fill }): ReactElement => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.6843 17.2693C3.56868 17.2691 3.45437 17.2448 3.34864 17.1981C3.24291 17.1513 3.14806 17.083 3.07013 16.9976C2.9908 16.9129 2.93026 16.8124 2.89249 16.7027C2.85472 16.593 2.84057 16.4765 2.85097 16.361L3.05513 14.116L12.4868 4.68762L15.4343 7.63429L6.00513 17.0618L3.76013 17.266C3.73492 17.2683 3.70962 17.2694 3.6843 17.2693V17.2693ZM16.0226 7.04512L13.076 4.09845L14.8435 2.33095C14.9209 2.25347 15.0128 2.19201 15.1139 2.15007C15.2151 2.10813 15.3235 2.08655 15.433 2.08655C15.5426 2.08655 15.651 2.10813 15.7522 2.15007C15.8533 2.19201 15.9452 2.25347 16.0226 2.33095L17.7901 4.09845C17.8676 4.17585 17.9291 4.26776 17.971 4.36892C18.013 4.47009 18.0345 4.57852 18.0345 4.68804C18.0345 4.79755 18.013 4.90599 17.971 5.00715C17.9291 5.10832 17.8676 5.20023 17.7901 5.27762L16.0235 7.04429L16.0226 7.04512V7.04512Z"
        fill={fill ? fill : "white"}
      />
    </svg>
  );
};
