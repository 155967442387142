import { ReactElement } from "react";
import { FormInput, NumberFormatField, FromSelect, AutoComplete, FormUploadFile } from "../../../../../components";
import { ErrorsLocationInfoModel, LocationInfoModel } from "../../../../../types/models/Provider/Location/LocationInfo";
import { Checkbox } from "@mui/material";

interface LocationInfoProps {
  locationTypes: any;
  states: any;
  cities: any;
  inputs: LocationInfoModel;
  errorsLocationInfo: ErrorsLocationInfoModel;
  validateErrorLocationInfo: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  handleAutoComplete?: (event: React.SyntheticEvent, value: any | [], name: any) => void;
}

export const LocationInfo = ({
  locationTypes,
  states,
  handleAutoComplete,
  cities,
  inputs,
  errorsLocationInfo: errors,
  validateErrorLocationInfo: validateError,
  onChange,
}: LocationInfoProps): ReactElement => {
  return (
    <>
      <div>
        <div className="mt-[22px]">
          <div className="text-[14px] font-[600] text-[#1F2124]">Location Info</div>
          <div className="mt-[20px]">
            <div className="grid grid-cols-[1fr_1fr] items-center gap-[50px]">
              <FormInput
                name="locationName"
                label="Location Name"
                value={inputs.locationName}
                validate={{ error: errors.locationName.error, message: errors.locationName.message }}
                required
                onChange={onChange}
                onBlur={validateError}
              />
              <NumberFormatField
                name="locationPhone"
                label="Phone Number"
                value={inputs.locationPhone}
                validate={{ error: errors.locationPhone.error, message: errors.locationPhone.message }}
                required
                onChange={onChange}
                onBlur={validateError}
              />
            </div>
            <div className="mt-[30px]">
              <div className="grid grid-cols-[1fr_1fr] items-center gap-[50px]">
                <FormInput
                  name="locationAddress"
                  label="Address"
                  value={inputs.locationAddress}
                  validate={{ error: errors.locationAddress.error, message: errors.locationAddress.message }}
                  required
                  onChange={onChange}
                  onBlur={validateError}
                />
                <FormInput
                  name="locationEmail"
                  label="Email"
                  value={inputs.locationEmail}
                  validate={{ error: errors.locationEmail.error, message: errors.locationEmail.message }}
                  required
                  onChange={onChange}
                  onBlur={validateError}
                />
              </div>
            </div>
            <div className="mt-[30px]">
              <div className="grid grid-cols-[1fr_1fr] items-center gap-[50px] ">
                <FromSelect
                  name="locationType"
                  label="Select Location Type"
                  options={locationTypes}
                  value={inputs.locationType}
                  validate={{ error: errors.locationType.error, message: errors.locationType.message }}
                  onChange={onChange}
                  required
                  onBlur={validateError}
                />
                <AutoComplete
                  name="locationState"
                  label="State"
                  value={inputs.locationState}
                  validate={{ error: errors.locationState.error, message: errors.locationState.message }}
                  options={states}
                  onChange={handleAutoComplete}
                  required
                  onBlur={validateError}
                />
              </div>
            </div>
            <div className="mt-[30px]">
              <div className="grid grid-cols-[1fr_1fr] items-center gap-[50px]">
                {inputs.locationState !== null && Object.keys(inputs.locationState).length > 0 && cities.length === 0 ? (
                  <FormInput
                    name="locationCity"
                    label="City"
                    value={inputs.locationCity}
                    validate={{ error: errors.locationCity.error, message: errors.locationCity.message }}
                    required
                    onChange={onChange}
                    onBlur={validateError}
                  />
                ) : (
                  <AutoComplete
                    name="locationCity"
                    label="City"
                    value={inputs.locationCity}
                    validate={{ error: errors.locationCity.error, message: errors.locationCity.message }}
                    options={cities}
                    onChange={handleAutoComplete}
                    required
                    onBlur={validateError}
                  />
                )}
                <NumberFormatField
                  name="locationZip"
                  label="Zip Code"
                  value={inputs.locationZip}
                  validate={{ error: errors.locationZip.error, message: errors.locationZip.message }}
                  required
                  onChange={onChange}
                  onBlur={validateError}
                />
              </div>
            </div>
            <div className="mt-[30px]">
              <div className="grid grid-cols-[1fr_1fr] items-center gap-[50px] ">
                <FormUploadFile
                  name="uploadStateLicenceOrURL"
                  label="Upload State License or URL"
                  value={inputs.uploadStateLicenceOrURL}
                  validate={{ error: errors.uploadStateLicenceOrURL.error, message: errors.uploadStateLicenceOrURL.message }}
                  required
                  onChange={onChange}
                  onBlur={validateError}
                />
                <div className="flex items-center ">
                  <Checkbox name="active" checked={inputs.active} color={inputs.active ? "success" : "default"} onChange={onChange} />
                  <span className="text-[#1F2124] text-[14px] font-[400]">{"Active"}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
