import React, { ReactElement, useEffect, useState, Fragment } from "react";
import "./CreateProfile.styles.scss";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CustomStepper, VAButton } from "../../../components";
import VAAlert from "../../../components/Alert/Alert";
import { onCreateProfile } from "../../../redux/actionCreators";
import { AppState } from "../../../redux/store";
import { AlertType } from "../../../shared/constants/AppConst";
import { BusinessInfoModel } from "../../../types/models/Provider/createProfile/BusinessInfo";
import { CreateProfileModel } from "../../../types/models/Provider/createProfile/CreateProfile";
import { PrimaryUserModel } from "../../../types/models/Provider/createProfile/PrimaryUser";
import { SecondaryUserModel } from "../../../types/models/Provider/createProfile/SecondaryUser";
import { useFormFieldsHandleChange } from "../../../utils";
import { getProviderRolesAndLocTypes } from "../../../redux/actionCreators/Provider/ManageUsers";
import { BusinessProfile, UserProfile } from "../../../sections/Provider/CreateProfile";
import Util from "../../../utils/util";

export const CreateProfile = (): ReactElement => {
  const { signupData } = useSelector<AppState, AppState["signup"]>(({ signup }) => signup);
  const navigate = useNavigate();
  const steps = ["Business Info", "User Info"];
  const [activeStep, setActiveStep] = useState(1);
  const [userTypes] = useState(["Single", "Multiple"]);
  const [userType, setUserType] = useState("Single");
  const [alertMessage, setAlertMessage] = useState<AlertType | null>(null);
  const [providerRoles, setProviderRoles] = useState([]);
  const [locationTypes, setLocationTypes] = useState([]);
  const [states, setStates] = useState<any[]>([]);
  const [cities, setCities] = useState<any[]>([]);
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // Business Info State and Handlers
  const [businessInfoInputs, setBusinessInfoInputs] = useState<BusinessInfoModel>({
    businessName: "",
    businessPhone: "",
    businessAddress: "",
    businessEmail: "",
    businessCity: null,
    businessLocationType: "",
    businessState: null,
    businessZip: "",
    uploadStateLicenceOrURL: "",
  });
  const [errorsBusinessInfo, setErrorsBusinessInfo] = useState({
    businessName: { error: false, message: "" },
    businessPhone: { error: false, message: "" },
    businessAddress: { error: false, message: "" },
    businessEmail: { error: false, message: "" },
    businessCity: { error: false, message: "" },
    businessLocationType: { error: false, message: "" },
    businessState: { error: false, message: "" },
    businessZip: { error: false, message: "" },
    uploadStateLicenceOrURL: { error: false, message: "" },
  });

  const handleBusinessFieldChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    const updatedBusinessInfo: BusinessInfoModel = { ...businessInfoInputs };
    (updatedBusinessInfo as any)[name] = value;
    setBusinessInfoInputs(updatedBusinessInfo);
  };
  const handleAutoComplete = (event: React.SyntheticEvent, value: any | [], name: any) => {
    // Create a copy of the current businessInfo
    const updatedBusinessInfo: BusinessInfoModel = { ...businessInfoInputs };

    // Use type assertion to tell TypeScript that `name` is a valid key
    (updatedBusinessInfo as any)[name] = value;

    // Update the state with the modified businessInfo
    setBusinessInfoInputs(updatedBusinessInfo);
    if (name === "businessState") {
      if (value === null) {
        updatedBusinessInfo.businessCity = null;
        setCities([]); // Assuming setCities is a state updater for cities, reset it to an empty array when state is null
        return; // Exit the function early
      }
      updatedBusinessInfo.businessCity = null;
      const cities = Util.getCities(value);
      // const cities = City.getCitiesOfState("US", value.isoCode);
      setCities(cities);
    }
  };
  const validateErrorBusinessInfo = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!e.target.value) {
      setErrorsBusinessInfo({ ...errorsBusinessInfo, [e.target.name]: { error: true, message: "" } });
    } else {
      setErrorsBusinessInfo({ ...errorsBusinessInfo, [e.target.name]: { error: false, message: "" } });
    }
  };
  const validateFormBusinessInfo = () => {
    let formIsValid = true;
    let localErrorsBusinessInfo = errorsBusinessInfo;

    if (businessInfoInputs.businessAddress === "") {
      formIsValid = false;
      localErrorsBusinessInfo.businessAddress = { error: true, message: "Address is Required!" };
    }
    if (businessInfoInputs.businessCity === "") {
      formIsValid = false;
      localErrorsBusinessInfo.businessCity = { error: true, message: "City is Required!" };
    }
    if (businessInfoInputs.businessEmail === "") {
      formIsValid = false;
      localErrorsBusinessInfo.businessEmail = { error: true, message: "Email is Required!" };
    } else if (!emailPattern.test(businessInfoInputs.businessEmail)) {
      formIsValid = false;
      localErrorsBusinessInfo.businessEmail = { error: true, message: "Invalid Email!" };
    }
    if (businessInfoInputs.businessLocationType === "") {
      formIsValid = false;
      localErrorsBusinessInfo.businessLocationType = { error: true, message: "Location Type is Required!" };
    }
    if (businessInfoInputs.businessName === "") {
      formIsValid = false;
      localErrorsBusinessInfo.businessName = { error: true, message: "Name is Required!" };
    }
    if (businessInfoInputs.businessName.length < 3) {
      formIsValid = false;
      localErrorsBusinessInfo.businessName = { error: true, message: "Name should have at least three letters!" };
    }
    if (businessInfoInputs.businessPhone === "") {
      formIsValid = false;
      localErrorsBusinessInfo.businessPhone = { error: true, message: "Phone Number is Required!" };
    }

    if (businessInfoInputs.businessState === "") {
      formIsValid = false;
      localErrorsBusinessInfo.businessState = { error: true, message: "State is Required!" };
    }
    if (businessInfoInputs.businessZip === "") {
      formIsValid = false;
      localErrorsBusinessInfo.businessZip = { error: true, message: "Zip Code is Required!" };
    }
    if (businessInfoInputs.uploadStateLicenceOrURL === "") {
      formIsValid = false;
      localErrorsBusinessInfo.uploadStateLicenceOrURL = { error: true, message: "Required Field!" };
    }
    setErrorsBusinessInfo({ ...errorsBusinessInfo, ...localErrorsBusinessInfo });
    return formIsValid;
  };
  const onSubmitBusinessInfo = async () => {
    if (!validateFormBusinessInfo()) return;
    setActiveStep(1);
  };

  // Primary User State and Handlers
  const [primaryUser] = useState<PrimaryUserModel>({ firstName: signupData.firstName, lastName: signupData.lastName, email: signupData.email, phone: "", role: "" });
  const [primaryUserInputs, handlePrimaryUserChange] = useFormFieldsHandleChange(primaryUser);
  const [errorsPrimaryUser, setErrorsPrimaryUser] = useState({
    firstName: { error: false, message: "" },
    lastName: { error: false, message: "" },
    phone: { error: false, message: "" },
    role: { error: false, message: "" },
    email: { error: false, message: "" },
  });
  const validateErrorPrimaryUser = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!e.target.value) {
      setErrorsPrimaryUser({ ...errorsPrimaryUser, [e.target.name]: { error: true, message: "Required Field!" } });
    } else {
      setErrorsPrimaryUser({ ...errorsPrimaryUser, [e.target.name]: { error: false, message: "" } });
    }
  };
  const validateFormPrimaryUser = () => {
    let formIsValid = true;
    let localErrorsPrimaryUser = errorsPrimaryUser;
    if (primaryUserInputs.firstName === "") {
      formIsValid = false;
      localErrorsPrimaryUser.firstName = { error: true, message: "Required Field!" };
    }
    if (primaryUserInputs.lastName === "") {
      formIsValid = false;
      localErrorsPrimaryUser.lastName = { error: true, message: "Required Field!" };
    }

    if (primaryUserInputs.email === "") {
      formIsValid = false;
      localErrorsPrimaryUser.email = { error: true, message: "Required Field!" };
    } else if (!emailPattern.test(primaryUserInputs.email)) {
      formIsValid = false;
      localErrorsPrimaryUser.email = { error: true, message: "Invalid Email!" };
    }

    if (primaryUserInputs.phone === "") {
      formIsValid = false;
      localErrorsPrimaryUser.phone = { error: true, message: "Required Field!" };
    }
    if (primaryUserInputs.role === "") {
      formIsValid = false;
      localErrorsPrimaryUser.role = { error: true, message: "Required Field!" };
    }
    setErrorsPrimaryUser({ ...errorsPrimaryUser, ...localErrorsPrimaryUser });
    return formIsValid;
  };

  const onSubmitCreateProfile = async () => {
    try {
      //To prevent to move further in case of empty fields
      if (!validateFormPrimaryUser()) return;
      const createProfileData: CreateProfileModel = {
        signupData: signupData,
        primaryUser: primaryUserInputs,
        businessInfo: businessInfoInputs,
        secondaryUsers: secondaryUsers,
      };

      let res = await onCreateProfile(createProfileData);
      setAlertMessage({ type: "info", description: res.data, open: true });
      navigate("/?registered=true");
    } catch (err) {
      setAlertMessage({ type: "error", description: "Something went wrong, please contact with the administrator", open: true });
    }
  };

  // Secondary User staState and Handlers
  const [secondaryUsers, setSecondaryUsers] = useState<SecondaryUserModel[]>([]);
  const [secondaryUser] = useState<SecondaryUserModel>({ firstName: "", lastName: "", email: "", role: "", phone: "", password: "", confirm_password: "", permissions: [] });
  const [secondaryUserInputs, setSecondaryUserInputs] = useState<SecondaryUserModel>(secondaryUser);
  const handleSecondaryUserChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSecondaryUserInputs({ ...secondaryUserInputs, [e.target.name]: e.target.value });
  };

  const handleResetSecondaryUser = () => {
    setSecondaryUserInputs(secondaryUser);
  };

  const [errorsSecondaryUser, setErrorsSecondaryUser] = useState({
    firstName: { error: false, message: "" },
    lastName: { error: false, message: "" },
    password: { error: false, message: "" },
    confirm_password: { error: false, message: "" },
    email: { error: false, message: "" },
    role: { error: false, message: "" },
    phone: { error: false, message: "" },
  });

  const validateErrorSecondaryUser = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!e.target.value) {
      setErrorsSecondaryUser({ ...errorsSecondaryUser, [e.target.name]: { error: true, message: "Required Field!" } });
    } else {
      setErrorsSecondaryUser({ ...errorsSecondaryUser, [e.target.name]: { error: false, message: "" } });
    }
  };

  const validateFormSecondaryUser = () => {
    let formIsValid = true;
    let localErrorsSecondaryUser = errorsSecondaryUser;

    if (secondaryUserInputs.firstName === "") {
      formIsValid = false;
      localErrorsSecondaryUser.firstName = { error: true, message: "Required Field!" };
    }
    if (secondaryUserInputs.firstName.length < 3) {
      formIsValid = false;
      localErrorsSecondaryUser.firstName = { error: true, message: "Name should have at least three letters!" };
    }
    if (secondaryUserInputs.lastName === "") {
      formIsValid = false;
      localErrorsSecondaryUser.lastName = { error: true, message: "Required Field!" };
    }
    if (secondaryUserInputs.email === "") {
      formIsValid = false;
      localErrorsSecondaryUser.email = { error: true, message: "Required Field!" };
    } else if (!emailPattern.test(secondaryUserInputs.email)) {
      formIsValid = false;
      localErrorsSecondaryUser.email = { error: true, message: "Invalid Email!" };
    }
    if (secondaryUserInputs.role === "") {
      formIsValid = false;
      localErrorsSecondaryUser.role = { error: true, message: "Required Field!" };
    }

    if (secondaryUserInputs.password === "") {
      formIsValid = false;
      localErrorsSecondaryUser.password = { error: true, message: "Required Field!" };
    }
    if (secondaryUserInputs.confirm_password === "") {
      formIsValid = false;
      localErrorsSecondaryUser.confirm_password = { error: true, message: "Required Field!" };
    }

    if (secondaryUserInputs.password !== secondaryUserInputs.confirm_password) {
      formIsValid = false;
      localErrorsSecondaryUser.password = { error: true, message: "Password should be same as Confirm Password!" };
      localErrorsSecondaryUser.confirm_password = { error: true, message: "Confirm Password should be same as Password!" };
    }
    setErrorsSecondaryUser({ ...errorsSecondaryUser, ...localErrorsSecondaryUser });
    return formIsValid;
  };
  const fetchData = async () => {
    try {
      const res = await getProviderRolesAndLocTypes();
      setProviderRoles(res.providerRoles);
      setLocationTypes(res.locationTypes);
      const states: any[] = Util.getStates();
      setStates(states);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchData();
    setActiveStep(0);
  }, []);

  return (
    <Fragment>
      {alertMessage !== null ? <VAAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} /> : null}
      <div className="outer">
        <div className="outer__left">
          <div className="outer__left-main-img">
            <img src={`${process.env.PUBLIC_URL}/imgs/sider-image.png`} alt="Vaccifi" />
          </div>
          <div className="outer__left-secondary-img">
            <img src={`${process.env.PUBLIC_URL}/imgs/sider-text-image.svg`} alt="vaccine text" />
          </div>
        </div>
        <div className="outer__right overflow-y-auto">
          <div className="outer__right-header">
            <h1 className="outer__right-header-heading">Create Profile</h1>
            <p className="outer__right-header-text">Enter your Business and Personal Details</p>
          </div>
          <div className="outer__right-stepper">
            <CustomStepper steps={steps} activeStep={activeStep} />
            {activeStep === 0 ? (
              <BusinessProfile
                locationTypes={locationTypes}
                states={states}
                cities={cities}
                handleAutoComplete={handleAutoComplete}
                inputs={businessInfoInputs}
                errorsBusinessInfo={errorsBusinessInfo}
                validateErrorBusinessInfo={validateErrorBusinessInfo}
                onChange={handleBusinessFieldChange}
              />
            ) : null}
            {activeStep === 1 ? (
              <UserProfile
                providerRoles={providerRoles}
                userTypes={userTypes}
                userType={userType}
                setUserType={setUserType}
                // Primary User Props
                primaryUserInputs={primaryUserInputs}
                errorsPrimaryUser={errorsPrimaryUser}
                validateErrorPrimaryUser={validateErrorPrimaryUser}
                handlePrimaryUserChange={handlePrimaryUserChange}
                // Secondary User Props
                setSecondaryUserInputs={setSecondaryUserInputs}
                secondaryUserInputs={secondaryUserInputs}
                errorsSecondaryUser={errorsSecondaryUser}
                validateErrorSecondaryUser={validateErrorSecondaryUser}
                validateFormSecondaryUser={validateFormSecondaryUser}
                handleSecondaryUserChange={handleSecondaryUserChange}
                secondaryUsers={secondaryUsers}
                setSecondaryUsers={setSecondaryUsers}
                handleResetSecondaryUser={handleResetSecondaryUser}
              />
            ) : null}
            <div className="mt-[40px] flex justify-end mb-[20px]">
              {activeStep === 0 ? (
                <VAButton text="Next" dark onClick={onSubmitBusinessInfo} />
              ) : (
                <div className="grid grid-cols-[1fr_1fr] items-center gap-[50px]">
                  <VAButton text="Back" light onClick={() => setActiveStep(0)} />
                  <VAButton text={userType === "Single" ? "Create profile" : "Create profile and send invites"} dark onClick={onSubmitCreateProfile} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
