import { Box, Checkbox, Modal } from "@mui/material";
import { ReactElement, useState, Fragment } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import { VAButton } from "../VAButton/VAButton.component";
import { getFormattedDate } from "../../shared/Util";

const style = { width: "90%", bgcolor: "background.paper", boxShadow: 24, borderRadius: "20px", p: 4, marginTop: "10px" };

interface ViewPaperworkModalProps {
  paperWorkModalData: any;
  openPaperworkModal: boolean;
  setopenPaperworkModal: (a: boolean) => void;
}

export const ViewPaperworkModal = ({ paperWorkModalData, openPaperworkModal = false, setopenPaperworkModal }: ViewPaperworkModalProps): ReactElement => {
  const [prev, setPrev] = useState<boolean>(true);
  const [selectedDropdown, setSelectedDropdown] = useState<string | undefined>(paperWorkModalData?.screeningData[0]?.screening_name);

  const openDD = (name: string) => {
    setPrev(!prev);
    setSelectedDropdown(name);
  };

  const onSave = () => {
    setopenPaperworkModal(false);
  };
  const formStatus = `Consent form ${paperWorkModalData?.vaccine_type === "VACCINE" ? "and VIS" : ""} ${paperWorkModalData?.patient_consent_data.consent_check ? "Signed" : "Not signed"} `;

  return (
    <div>
      <Modal
        open={openPaperworkModal}
        style={{
          display: "flex",
          alignItems: `${prev ? "baseline" : "center"}`,
          overflowY: "scroll",
          justifyContent: "center",
        }}
        onClose={() => setopenPaperworkModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="">
            <div className="flex justify-center">
              <h2 className="text-[16px] md:text-[20px] text-[#000000] font-[600]">Pre-Appointment Paper Work</h2>
            </div>
            <div className="mt-[10px]">
              <p className="text-[#888A8C]">
                Signature: <span className="text-[#000000]">{paperWorkModalData?.patient_consent_data.signature}</span>
              </p>
              {paperWorkModalData?.patient_consent_data?.legal_representative_name && (
                <Fragment>
                  <p className="text-[#888A8C]">
                    Legal Representative Name: <span className="text-[#000000]">{paperWorkModalData?.patient_consent_data?.legal_representative_name}</span>
                  </p>
                  <p className="text-[#888A8C]">
                    Relationship to Patient: <span className="text-[#000000]">{paperWorkModalData?.patient_consent_data.relationship_to_patient}</span>
                  </p>
                </Fragment>
              )}

              <p className="text-[#888A8C]">
                Date of Signature: <span className="text-[#000000]">{getFormattedDate(paperWorkModalData?.patient_consent_data.date_of_signature)}</span>
              </p>
              <p className="text-[#888A8C]">
                Form Status:&nbsp;
                <span className="text-[#000000]">{formStatus}</span>
              </p>
            </div>
            <div className="mt-[24px]" style={{ marginTop: "20px" }}>
              {paperWorkModalData?.screeningData?.map((element: any, index: number) => {
                return (
                  <div className="items-center justify-between pb-[9px]" key={element.screening_id}>
                    <div onClick={() => openDD(element.screening_name)} className="flex items-center cursor-pointer justify-between pb-[9px] border-b-[1px] border-[#878a8c] py-[8px]">
                      <h2 className="text-[18px] font-[600] text-primary-main">{element.screening_name}</h2>
                      <div className="flex gap-[10px] items-center cursor-pointer">
                        <span onClick={() => openDD(element.screening_name)}>
                          <AiFillCaretDown />
                        </span>
                      </div>
                    </div>
                    {prev && selectedDropdown === element.screening_name ? (
                      <div className={`transition-all`}>
                        {element.screening_questions_List?.map((data: any, index: any) => (
                          <div className="border-b-[1px] border-[#9a9ea1] py-[8px]">
                            <p className="text-[14px] font-[400] text-primary-main">
                              {index + 1}. {data.screeningData.question}
                            </p>
                            <div className="flex flex-col gap-[8px] md:gap-[80px] md:flex-row">
                              {data.options?.map((option: any, index: any) => (
                                <>
                                  <div key={option} className="flex items-center ">
                                    <span key={option} className="text-[#1F2124] text-[14px] font-[400]">
                                      <ol>
                                        {option.isChecked === true ? (
                                          <li>
                                            <Checkbox checked={option.isChecked} color="success" />
                                            {index + 1}. {option.text}
                                          </li>
                                        ) : (
                                          <li>
                                            {index + 1}. {option.text}
                                          </li>
                                        )}
                                      </ol>
                                    </span>
                                  </div>
                                </>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>

            <div className="mt-[10px] flex justify-end cursor-pointer" onClick={() => onSave()}>
              <VAButton outlined text="Close" />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
