import { ReactElement } from "react";
import { FormInput, NumberFormatField, FromSelect, AutoComplete, FormUploadFile } from "../../../../components";
import { BusinessInfoModel, ErrorsBusinessInfoModel } from "../../../../types/models/Provider/createProfile/BusinessInfo";
interface BusinessInfoProps {
  locationTypes: any;
  states: any;
  cities: any;
  inputs: BusinessInfoModel;
  errorsBusinessInfo: ErrorsBusinessInfoModel;
  validateErrorBusinessInfo: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  handleAutoComplete?: (event: React.SyntheticEvent, value: any | [], name: any) => void;
}

export const BusinessProfile = ({
  locationTypes,
  states,
  handleAutoComplete,
  cities,
  inputs,
  errorsBusinessInfo: errors,
  validateErrorBusinessInfo: validateError,
  onChange,
}: BusinessInfoProps): ReactElement => {
  return (
    <>
      <div>
        <div className="mt-[22px]">
          <div className="text-[14px] font-[600] text-[#1F2124]">Business Info</div>
          <div className="mt-[20px]">
            <div className="grid grid-cols-[1fr_1fr] items-center gap-[50px]">
              <FormInput
                name="businessName"
                label="Business Name"
                value={inputs.businessName}
                validate={{ error: errors.businessName.error, message: errors.businessName.message }}
                required
                onChange={onChange}
                onBlur={validateError}
              />
              <NumberFormatField
                name="businessPhone"
                label="Phone Number"
                value={inputs.businessPhone}
                validate={{ error: errors.businessPhone.error, message: errors.businessPhone.message }}
                required
                onChange={onChange}
                onBlur={validateError}
              />
            </div>
            <div className="mt-[30px]">
              <div className="grid grid-cols-[1fr_1fr] items-center gap-[50px]">
                <FormInput
                  name="businessAddress"
                  label="Address"
                  value={inputs.businessAddress}
                  validate={{ error: errors.businessAddress.error, message: errors.businessAddress.message }}
                  required
                  onChange={onChange}
                  onBlur={validateError}
                />
                <FormInput
                  name="businessEmail"
                  label="Email"
                  value={inputs.businessEmail}
                  validate={{ error: errors.businessEmail.error, message: errors.businessEmail.message }}
                  required
                  onChange={onChange}
                  onBlur={validateError}
                />
              </div>
            </div>
            <div className="mt-[30px]">
              <div className="grid grid-cols-[1fr_1fr] items-center gap-[50px] ">
                <FromSelect
                  name="businessLocationType"
                  label="Select Provider Type"
                  options={locationTypes}
                  value={inputs.businessLocationType}
                  validate={{ error: errors.businessLocationType.error, message: errors.businessLocationType.message }}
                  onChange={onChange}
                  required
                  onBlur={validateError}
                />
                <AutoComplete
                  name="businessState"
                  label="State"
                  value={inputs.businessState}
                  validate={{ error: errors.businessState.error, message: errors.businessState.message }}
                  options={states}
                  onChange={handleAutoComplete}
                  required
                  onBlur={validateError}
                />
              </div>
            </div>
            <div className="mt-[30px]">
              <div className="grid grid-cols-[1fr_1fr] items-center gap-[50px]">
                {inputs.businessState !== null && Object.keys(inputs.businessState)?.length > 0 && cities.length === 0 ? (
                  <FormInput
                    name="businessCity"
                    label="City"
                    value={inputs.businessCity}
                    validate={{ error: errors.businessCity.error, message: errors.businessCity.message }}
                    required
                    onChange={onChange}
                    onBlur={validateError}
                  />
                ) : (
                  <AutoComplete
                    name="businessCity"
                    label="City"
                    value={inputs.businessCity}
                    validate={{ error: errors.businessCity.error, message: errors.businessCity.message }}
                    options={cities}
                    onChange={handleAutoComplete}
                    required
                    onBlur={validateError}
                  />
                )}
                <NumberFormatField
                  name="businessZip"
                  label="Zip Code"
                  value={inputs.businessZip}
                  validate={{ error: errors.businessZip.error, message: errors.businessZip.message }}
                  required
                  onChange={onChange}
                  onBlur={validateError}
                />
              </div>
            </div>
            <div className="mt-[30px]">
              <div className="grid grid-cols-[1fr_1fr] items-center gap-[50px] ">
                <FormUploadFile
                  name="uploadStateLicenceOrURL"
                  label="Upload State License or URL"
                  value={inputs.uploadStateLicenceOrURL}
                  validate={{ error: errors.uploadStateLicenceOrURL.error, message: errors.uploadStateLicenceOrURL.message }}
                  required
                  onChange={onChange}
                  onBlur={validateError}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
