import { Box, Checkbox, Modal } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import { useSelector } from "react-redux";
import { getVaccineScreeningData, updateScreeningList } from "../../redux/actionCreators";
import { AppState } from "../../redux/store";
import { VAButton } from "../VAButton/VAButton.component";
import { DashboardCard } from "../DashboardCard/DashboardCard.component";

interface ScreeningModalProps {
  page: any;
  open: boolean;
  setOpen: (a: boolean) => void;
  modalVaccineId: any;
  selectedVaccine: any;
}

const style = {
  width: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  p: 4,
  marginTop: "10px",
};

export const ScreeningModal = ({ page, open = false, setOpen, modalVaccineId, selectedVaccine }: ScreeningModalProps): ReactElement => {
  const [dataExists, setDataExists] = useState<boolean>(false);
  const { vaccines }: any = useSelector<AppState, AppState["AddVaccine"]>(({ AddVaccine }) => AddVaccine);
  const [modalVaccineData, setModalVaccineData] = useState<any>({ modalVaccineData: [] });
  const [prev, setPrev] = useState<boolean>(false);
  const [selectedDropdown, setSelectedDropdown] = useState<string | undefined>(undefined);
  const { userData } = useSelector<AppState, AppState["User"]>(({ User }) => User);

  const openDD = (name: string) => {
    setPrev(!prev);
    setSelectedDropdown(name);
  };

  const onCheckBoxClick = (item: any, index: any) => {
    item.isChecked = !item.isChecked;
    if (page === "add vaccine" || page === "add test") setModalVaccineData((prev2: any) => prev2.map((i: any) => (i.screening_id === item.screening_id ? item : i)));
    else {
      let updatedData = modalVaccineData.data.map((i: any) => (i.screening_id === item.screening_id ? item : i));
      let res = { data: updatedData };
      setModalVaccineData(res);
    }
  };

  const onSave = () => {
    selectedVaccine.screeningQuestions = modalVaccineData.filter((x: any) => x.isChecked === true);
    setOpen(false);
  };

  const onUpdate = async () => {
    selectedVaccine[0].screening = modalVaccineData.data.filter((x: any) => x.isChecked === true);
    const providerVacID = selectedVaccine[0].prov_vaccine_id;
    const VaccineScreeningIdList = selectedVaccine[0].screening.map((item: any) => item.vaccine_screening_id);

    let data = {
      biz_branch_id: userData.selected_branch.biz_branch_id,
      prov_vaccine_id: providerVacID,
      vaccine_screening_ids_List: VaccineScreeningIdList,
    };
    await updateScreeningList(data);
    setOpen(false);
  };

  useEffect(() => {
    const getScreeningData = async (vaccine_id: any) => {
      try {
        const data = { biz_branch_id: userData.selected_branch.biz_branch_id, vaccine_id: vaccine_id };
        let res = await getVaccineScreeningData(data);
        setModalVaccineData(res);
      } catch (err) {
        console.log(err);
      }
    };
    if (selectedVaccine.length !== 0) {
      let vaccine_id = selectedVaccine[0].vaccine_id;
      if (page === "add vaccine" || page === "add test") {
        setModalVaccineData(selectedVaccine[0].screeningQuestions);
      } else {
        //get vaccine id from here and get their screening Questions and set modal vaccine data
        getScreeningData(vaccine_id);
        //on checking/unchecking save the isChecked and on Save save it in the database table prov_vaccine_screening
      }
      setDataExists(true);
    }
  }, [userData, modalVaccineId, selectedVaccine, vaccines?.vaccineList, page, setModalVaccineData, setDataExists]);

  return (
    <div>
      <Modal
        open={open}
        style={{
          display: "flex",
          alignItems: `${prev ? "baseline" : "center"}`,
          overflowY: "scroll",
          justifyContent: "center",
        }}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="">
            <div className="flex justify-center">
              <h2 className="text-[16px] md:text-[20px] text-[#000000] font-[600]">Select Screening Questions Document</h2>
            </div>
            {dataExists ? (
              <div className="mt-[24px]" style={{ marginTop: "20px" }}>
                {page === "add vaccine" || page === "add test"
                  ? modalVaccineData?.map((element: any, index: any) => (
                      <div className="items-center justify-between pb-[9px]" key={element.screening_id}>
                        <Checkbox checked={element.isChecked} onChange={() => onCheckBoxClick(element, index)} color="success" />
                        <DashboardCard>
                          <div className="bg-[#FFFFFF] p-[20px] rounded-[12px]" style={{ width: "-webkit-fill-available" }}>
                            <div onClick={() => openDD(element.screening_name)} className="flex items-center cursor-pointer justify-between pb-[9px]">
                              <h2 className="text-[18px] font-[500] text-[#000000]">{element.screening_name}</h2>
                              <div className="flex gap-[10px] items-center cursor-pointer">
                                <span onClick={() => openDD(element.screening_name)}>
                                  <AiFillCaretDown />
                                </span>
                              </div>
                            </div>
                            {prev && selectedDropdown === element.screening_name ? (
                              <div className={`transition-all`}>
                                {element.screening_questions?.map((data: any, index: any) => (
                                  <div className=" border-b-[1px] border-[ #0F5175] py-[8px]">
                                    <p className="text-[14px] font-[400] text-primary-main">
                                      {index + 1}. {data.screening_Questions_List.questions}
                                    </p>
                                    <div className="flex flex-col gap-[8px] md:gap-[80px] md:flex-row">
                                      {data.options?.map((option: any) => (
                                        <>
                                          <div key={option} className="flex items-center ">
                                            <span key={option} className="text-[#1F2124] text-[14px] font-[400]">
                                              <ol>
                                                <li>{option.options}</li>
                                              </ol>
                                            </span>
                                          </div>
                                        </>
                                      ))}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : null}
                          </div>
                        </DashboardCard>
                      </div>
                    ))
                  : modalVaccineData.data?.map((element: any, index: any) => (
                      <div className="items-center justify-between pb-[9px]" key={element.screening_id}>
                        <Checkbox
                          checked={element.isChecked}
                          onChange={() => onCheckBoxClick(element, index)}
                          //onChange = {() => {element.isChecked = !element.isChecked; console.log('asd',element)}}
                          color="success"
                        />
                        <DashboardCard>
                          <div className="bg-[#FFFFFF] p-[20px] rounded-[12px]" style={{ width: "-webkit-fill-available" }}>
                            <div onClick={() => openDD(element.screening_name)} className="flex items-center cursor-pointer  justify-between pb-[9px]">
                              <h2 className="text-[18px] font-[500] text-[#000000]">{element.screening_name}</h2>
                              <div className="flex gap-[10px] items-center cursor-pointer">
                                <span onClick={() => openDD(element.screening_name)}>
                                  <AiFillCaretDown />
                                </span>
                              </div>
                            </div>
                            {prev && selectedDropdown === element.screening_name ? (
                              <div className={`transition-all`}>
                                {element.screening_questions?.map((data: any, index: any) => (
                                  <div className=" border-b-[1px] border-[ #0F5175] py-[8px]">
                                    <p className="text-[14px] font-[400] text-primary-main">
                                      {index + 1}. {data.screening_Questions_List.questions}
                                    </p>
                                    <div className="flex flex-col gap-[8px] md:gap-[80px] md:flex-row">
                                      {data.options?.map((option: any) => (
                                        <>
                                          <div key={option} className="flex items-center ">
                                            <span key={option} className="text-[#1F2124] text-[14px] font-[400]">
                                              <ol>
                                                <li>{option.options}</li>
                                              </ol>
                                            </span>
                                          </div>
                                        </>
                                      ))}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : null}
                          </div>
                        </DashboardCard>
                      </div>
                    ))}
              </div>
            ) : null}

            {page === "add vaccine" || page === "add test" ? (
              <div className="mt-[10px] flex justify-end cursor-pointer" onClick={() => onSave()}>
                <VAButton outlined text="Close" />
              </div>
            ) : (
              <div className="mt-[10px] flex justify-end cursor-pointer" onClick={() => onUpdate()}>
                <VAButton outlined text="Save" />
              </div>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
};
