import { ReactElement, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import Multiselect from "multiselect-react-dropdown";
import { AddVaccineModel } from "../../../types/models/Provider/AddVaccine";
import { useDispatch, useSelector } from "react-redux";
import { getVaccineCategory, getVaccineDataByCategory, AddNewVaccine, getVIS } from "../../../redux/actionCreators";
import { AppState } from "../../../redux/store";
import { VaccineCards } from "../../../sections/Provider/AddVaccine";
import { GET_VACCINES } from "../../../types/actions/Provider/AddVaccine.action";
import { useFormFieldsHandleChange } from "../../../utils";
import { DashboardLayout, FromSelect, FormInput, VAButton, RemoveModal, ScreeningModal } from "../../../components";
import { Back } from "../../../icons";
import { UploadVISDialog } from "../VaccinesAndPaperwork/VaccinesAndPaperwork.page";

export const AddVaccine = (): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [vaccineDataList, setVaccineList] = useState([]);
  const [isVaccineSelected, setIsVaccineSelected] = useState(0);
  const [selectedVaccine, setselectedVaccine] = useState<any>([]);
  const [modalVaccineId, setModalVaccineId] = useState<any>([]);
  const page = "add vaccine";
  const [selectedScreeningId, setScreeningId] = useState<any>(0);
  const [categories, setCategories] = useState<{ value: string; label: string }[]>([]);
  const { userData } = useSelector<AppState, AppState["User"]>(({ User }) => User);
  const { provVaccines } = useSelector<AppState, AppState["VaccineAndPaperwork"]>(({ VaccineAndPaperwork }) => VaccineAndPaperwork);
  // START VIS STATES
  const [visModalOpen, setVISModalOpen] = useState(false);
  const [selectedVISDocumentId, setSelectedVISDocumentId] = useState("");
  const [selectedVISDocument, setSelectedVISDocument] = useState("");
  const [visDocuments, setVISDocuments] = useState<{ value: string; label: string }[]>([]);
  const [errorsVIS, setErrorsVIS] = useState({ selectedVISDocument: { error: false, message: "" } });
  // END VIS STATES

  const getVaccineCategoryFunc = async () => {
    const data = await getVaccineCategory();
    setCategories(data?.categories);
  };

  const [AddVaccine] = useState<AddVaccineModel>({
    category: "",
    vaccineData: [],
    v_age_greater: 0,
    v_age_less: 0,
  });

  const [AddVaccineInputs, handleAddVaccineChange] = useFormFieldsHandleChange(AddVaccine);

  useEffect(() => {
    getVaccineCategoryFunc();
    setIsVaccineSelected(0);
  }, []);

  useEffect(() => {
    const getCatBasedVaccine = async () => {
      const alreadySelectedVaclist: any = provVaccines;
      if (AddVaccineInputs.category !== "") {
        let vaccineData = await getVaccineDataByCategory({ biz_branch_id: userData.selected_branch.biz_branch_id, categoryId: AddVaccineInputs.category });
        dispatch({ type: GET_VACCINES, payload: vaccineData });
        if (alreadySelectedVaclist !== undefined) {
          for (let i = 0; i < alreadySelectedVaclist?.length; i++) {
            let selectedVaccineId = alreadySelectedVaclist[i].vaccine_id.toString();
            for (let j = 0; j < vaccineData?.vaccineList.length; j++) {
              if (vaccineData.vaccineList[j].vaccine_id === selectedVaccineId) {
                vaccineData.vaccineList.splice(j, 1);
              }
            }
          }
        }
        setVaccineList(vaccineData?.vaccineList ? vaccineData?.vaccineList : []);
      }
    };
    getCatBasedVaccine();
  }, [AddVaccineInputs?.category, provVaccines, dispatch, userData]);

  const updateVaccineList = (list: any) => {
    let selectedVaccineList: any[] = [];
    for (let i = 0; i < list.length; i++) {
      let name = list[i];
      for (let y = 0; y < vaccineDataList.length; y++) {
        let data: any = vaccineDataList[y];
        if (data.vaccine_name === name) {
          data.screeningQuestions.forEach((element: any) => {
            element.isChecked = true;
          });
          selectedVaccineList.push(vaccineDataList[y]);
        }
      }
    }

    setselectedVaccine(selectedVaccineList);
    console.log("selectedVaccineList", selectedVaccineList);
    setIsVaccineSelected(1);
  };

  const handleSave = async () => {
    selectedVaccine.forEach((item: any) => {
      item.screeningQuestions = item.screeningQuestions.filter((sub: any) => sub.isChecked);
    });
    AddVaccineInputs.vaccineData.push(selectedVaccine);
    const AddVaccineData = {
      biz_branch_id: userData.selected_branch.biz_branch_id,
      v_data: AddVaccineInputs,
      type: "VACCINE",
    };
    await AddNewVaccine(AddVaccineData);
    navigate("/vaccines-paperwork");
  };

  const handleRemove = (vaccineId: any, screeningId: any) => {
    let modalVaccine = selectedVaccine.filter((x: any) => x.vaccine_id === vaccineId);
    if (modalVaccine.length > 0) {
      modalVaccine[0].screeningQuestions.forEach((element: any) => {
        if (element.screening_id === screeningId) {
          element.isChecked = false;
        }
      });
    }
    setRemoveModalOpen(false);
  };

  // START VIS methods
  const handleChange = (event: any) => {
    if (event.target.name === "selectedVISDocument") {
      // Get the selected document based on the value
      const selectedDocument = visDocuments.find((doc) => doc.value === event.target.value);

      // Set the selected VIS document ID and label
      if (selectedDocument) {
        setSelectedVISDocumentId(event.target.value as string);
        setSelectedVISDocument(selectedDocument.label);
      }
    }
  };

  useEffect(() => {
    if (visModalOpen) {
      const getVISData = async () => {
        try {
          const selectedVac = selectedVaccine.filter((x: any) => x.vaccine_id === modalVaccineId);
          let res = await getVIS({ prov_vaccine_id: selectedVac[0].prov_vaccine_id, biz_branch_id: selectedVac[0].biz_branch_id });
          let VISDocuments = [];
          for (let i = 0; i < res.data.length; i++) {
            const element = res.data[i];
            VISDocuments.push({ value: element.id, label: element.name });
          }
          setVISDocuments(VISDocuments);
          setSelectedVISDocument(res.selected_sa_vis_id === 0 ? res.selected_sa_vis_id : "");
        } catch (err) {
          console.log(err);
        }
      };
      getVISData();
    }
  }, [visModalOpen, selectedVaccine, modalVaccineId]);
  const validateErrorVIS = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!e.target.value) {
      setErrorsVIS({ ...errorsVIS, [e.target.name]: { error: true, message: "Required Field!" } });
    } else {
      setErrorsVIS({ ...errorsVIS, [e.target.name]: { error: false, message: "" } });
    }
  };
  const validateFormVIS = () => {
    let formIsValid = true;
    let localErrorsVIS = errorsVIS;

    if (selectedVISDocument === "") {
      formIsValid = false;
      localErrorsVIS.selectedVISDocument = { error: true, message: "Required Field!" };
    }
    setErrorsVIS({ ...errorsVIS, ...localErrorsVIS });
    return formIsValid;
  };
  const onClickEditVIS = async () => {
    setVISModalOpen(true);
    setSelectedVISDocument("");
    setSelectedVISDocumentId("");
    setErrorsVIS({ selectedVISDocument: { error: false, message: "" } });
  };
  const onClickUpdateVIS = async () => {
    try {
      //To prevent to move further in case of empty fields
      if (!validateFormVIS()) return;
      setVISModalOpen(false);
    } catch (err) {
      console.error(err);
    }
  };
  // END VIS methods
  return (
    <DashboardLayout>
      <div>
        <div className="flex items-center gap-[5px] cursor-pointer">
          <Back />
          <Link to="/vaccines-paperwork" className="text-[16px] font-[600] text-[#888A8C]">
            Back
          </Link>
        </div>
        <div className="mt-[14px]">
          <h2 className="text-[24px] text-primary-main font-[600]">Add Vaccine and Paperwork</h2>
        </div>
        <div className=" flex flex-col md:grid md:grid-cols-[1fr_1fr_1fr] w-full gap-[20px] mt-[24px]">
          <div className="flex flex-col gap-[12px]">
            <h2 className="text-[16px] font-[600] text-[#1F2124]">Category</h2>
            <FromSelect name="category" label="Category" value={AddVaccineInputs.category} onChange={handleAddVaccineChange} options={categories} />
          </div>
          <div className="flex flex-col gap-[12px]">
            <h2 className="text-[16px] font-[600] text-[#1F2124]">Vaccine</h2>
            <Multiselect
              placeholder="Select Vaccine"
              isObject={false}
              onRemove={(event) => {
                updateVaccineList(event);
              }}
              onSelect={(event) => {
                updateVaccineList(event);
              }}
              options={vaccineDataList.map((vaccineItem: any) => vaccineItem.vaccine_name)}
              style={{
                searchBox: {
                  width: "100%",
                  borderRadius: "8px",
                },
              }}
            />
          </div>

          <div className="flex items-end gap-[20px]">
            <FormInput heading="Age Group" label="Age greater than" name="v_age_greater" icon={<FaAngleRight />} onChange={handleAddVaccineChange} />
            <FormInput heading=" " label="Age less than" name="v_age_less" icon={<FaAngleLeft />} onChange={handleAddVaccineChange} />
          </div>
        </div>

        {isVaccineSelected === 1 ? (
          <>
            <div className="mt-[24px] grid grid-cols-[10%_auto] items-center gap-[20px]">
              <h2 className="text-[18px] text-[#114059] font-[600]">{categories.find((item: any) => item.value === AddVaccineInputs.category)?.label}</h2>

              <span className="border-t-[1px] border-primary-main"></span>
            </div>
            <div className="mt-[12px] ">
              <VaccineCards
                selectedVaccine={selectedVaccine}
                setOpen={setOpen}
                setModalVaccineId={setModalVaccineId}
                age_less={AddVaccineInputs.v_age_less}
                age_greater={AddVaccineInputs.v_age_greater}
                page={page}
                // handleRemove={handleRemove}
                handleCancel={undefined}
                setRemoveModalOpen={setRemoveModalOpen}
                setScreeningId={setScreeningId}
                selectedVISDocument={selectedVISDocument}
                onClickEditVIS={onClickEditVIS}
              />
            </div>
          </>
        ) : null}
        <div className="flex items-center justify-between py-[20px]">
          <VAButton outlined text="Cancel" onClick={() => navigate("/vaccines-paperwork")} />
          <VAButton dark text="Save" onClick={handleSave} />
        </div>
        <ScreeningModal page={page} open={open} selectedVaccine={selectedVaccine.filter((x: any) => x.vaccine_id === modalVaccineId)} modalVaccineId={modalVaccineId} setOpen={setOpen} />
        <RemoveModal
          page={page}
          removeModalOpen={removeModalOpen}
          setRemoveModalOpen={setRemoveModalOpen}
          handleRemove={handleRemove}
          selectedVaccine={selectedVaccine.filter((x: any) => x.vaccine_id === modalVaccineId)}
          selectedScreeningId={selectedScreeningId}
          modalVaccineId={modalVaccineId}
        />
        <UploadVISDialog
          visModalOpen={visModalOpen}
          setVISModalOpen={setVISModalOpen}
          visDocuments={visDocuments}
          selectedVISDocument={selectedVISDocumentId}
          errorsVIS={errorsVIS}
          validateErrorVIS={validateErrorVIS}
          handleChange={handleChange}
          onClickUpdateVIS={onClickUpdateVIS}
        />
      </div>
    </DashboardLayout>
  );
};
