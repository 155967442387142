import React, { useState, Fragment, useEffect } from "react";
import { Box, Card, Grid, Stack, Tooltip } from "@mui/material";
import { AutoComplete, ConfirmDialog, FormInput, FormUploadFile, FromSelect, NumberFormatField, VAButton } from "../../../components";
import Util from "../../../utils/util";
interface ViewMainBranchProps {
  mainBranch: any;
  editMainBranch: boolean;
  setEditMainBranch: any;
  allLocTypeOptions: any[];
  onClickUpdateBiz: any;
}
const ViewMainBranch = ({ mainBranch, editMainBranch, setEditMainBranch, allLocTypeOptions, onClickUpdateBiz }: ViewMainBranchProps) => {
  // State to hold the values of the main branch fields
  const [mainBranchData, setMainBranchData] = useState(mainBranch);
  const [states, setStates] = useState<any[]>([]);
  const [selectedState, setSelectedState] = useState<any>(null);
  const [selectedCity, setSelectedCity] = useState<any>(null);
  const [cities, setCities] = useState<any[]>([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  // Use useEffect to update the state when mainBranch prop changes
  useEffect(() => {
    if (mainBranch.length > 0) {
      setMainBranchData(mainBranch);

      setSelectedState(mainBranch[0].state);
      setSelectedCity(mainBranch[0].city);

      const states = Util.getStates();
      setStates(states);

      const cities = Util.getCities(mainBranch[0].state);
      setCities(cities);
    }
  }, [mainBranch]);

  // Handler for input changes
  const handleInputChange = (index: any, field: any, event: any) => {
    const newMainBranchData = [...mainBranchData];
    newMainBranchData[index][field] = event.target.value;
    setMainBranchData(newMainBranchData);
  };
  const handleAutoComplete = (event: React.SyntheticEvent, field: any, value: any | []) => {
    if (value === null) return; // Exit the function early

    if (field === "state") {
      const cities = Util.getCities(value);
      setSelectedState(value);
      setCities(cities);
      setSelectedCity(null);
    }
    if (field === "city") {
      setSelectedCity(value);
    }
  };
  const onClickConfirm = async () => {
    const newMainBranchData = mainBranchData.map((branch: any) => ({
      ...branch,
      state: selectedState ? selectedState : branch.state,
      city: selectedCity ? selectedCity : branch.city,
    }));
    await onClickUpdateBiz(newMainBranchData[0]);
    setOpenConfirm(false);
    setEditMainBranch(false);
  };

  return (
    <Fragment>
      <div>
        <h2 className="text-[24px] text-primary-main font-[600]">Business</h2>
      </div>
      <Box flex={1} mt={2}>
        <Box mx="auto">
          <Grid container spacing={2}>
            {mainBranchData.map((main: any, index: any) => (
              <Grid item xs={12} key={main.id}>
                <Card
                  sx={{
                    width: "100%",
                    padding: 2.5,
                    paddingLeft: { xs: 8, md: 2 },
                    overflow: "hidden",
                    borderRadius: 3,
                    boxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <Grid container spacing={2}>
                    {[
                      { label: "Name", value: main.name, field: "name" },
                      { label: "Location Type", value: main.loc_type, field: "loc_type" },
                      { label: "Email", value: main.email, field: "email" },
                      { label: "Phone Number", value: main.phone_no, field: "phone_no" },
                      { label: "Address", value: main.address, field: "address" },
                      { label: "State", value: main.state, field: "state" },
                      { label: "City", value: main.city, field: "city" },
                      { label: "Zip", value: main.zip, field: "zip" },
                      { label: "Licence", value: main.licence, field: "licence" },
                      { label: "Active", value: main.active ? "Granted" : "Denied", field: "active" },
                    ].map((field: any, fieldIndex: any) => (
                      <Grid item xs={3} key={fieldIndex}>
                        <Box>
                          <span className="text-[14px] font-[400] text-[#888A8C] max-w-[300px] pt-[12px]">{field.label}: </span>
                          <br />
                          {editMainBranch && field.field !== "active" ? (
                            field.field === "loc_type" ? (
                              <FromSelect name="loc_type" value={field.value} onChange={(e: any) => handleInputChange(index, field.field, e)} options={allLocTypeOptions} />
                            ) : field.field === "state" ? (
                              <AutoComplete name="state" value={selectedState} options={states} onChange={(e: any, value: any) => handleAutoComplete(index, field.field, value)} />
                            ) : field.field === "city" ? (
                              main.state !== null && Object.keys(main.state).length > 0 && cities.length === 0 ? (
                                <FormInput name="city" value={field.value} onChange={(e: any) => handleInputChange(index, field.field, e)} />
                              ) : (
                                <AutoComplete name="city" value={selectedCity} options={cities} onChange={(e: any, value: any) => handleAutoComplete(index, field.field, value)} />
                              )
                            ) : field.field === "zip" ? (
                              <NumberFormatField name="zip" value={field.value} onChange={(e: any) => handleInputChange(index, field.field, e)} />
                            ) : field.field === "licence" ? (
                              <FormUploadFile name="uploadStateLicenceOrURL" value={field.value} required onChange={(e: any) => handleInputChange(index, field.field, e)} />
                            ) : (
                              <FormInput variant="outlined" value={field.value} onChange={(e: any) => handleInputChange(index, field.field, e)} />
                            )
                          ) : (
                            <Tooltip title={field.value.length > 20 ? field.value : ""}>
                              <span className="field-value">{field.value.length > 20 ? field.value.slice(0, 20) + "..." : field.value}</span>
                            </Tooltip>
                          )}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                  <Grid container spacing={2} mt={1}>
                    <Grid item xs={12}>
                      {editMainBranch ? (
                        <Stack direction="row" alignItems="center" justifyContent="end" spacing={2}>
                          <VAButton text="Cancel" outlined size="small" onClick={() => setEditMainBranch(!editMainBranch)} />
                          <VAButton text="Save" dark size="small" onClick={() => setOpenConfirm(true)} />
                        </Stack>
                      ) : (
                        <Stack direction="row" alignItems="center" justifyContent="end" spacing={2}>
                          <VAButton text="Edit" dark size="small" onClick={() => setEditMainBranch(!editMainBranch)} />
                        </Stack>
                      )}
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <ConfirmDialog open={openConfirm} setOpen={setOpenConfirm} heading="Are you sure?" subHeading={`you want to update the business information`} onClickConfirm={onClickConfirm} />
    </Fragment>
  );
};

export default ViewMainBranch;
