import { ReactElement } from "react";
import { CoAdminUser } from "./CoAdminUser";
import { LocationSecondaryUser } from "./LocationSecondaryUser";
import { CoAdminUserModel, ErrorsCoAdminUserModel } from "../../../../types/models/Provider/Location/CoAdminUser";
import { ErrorsLocationSecondaryUserModel, LocationSecondaryUserModel } from "../../../../types/models/Provider/Location/LocationSecondaryUser";
import { MENU } from "../../../../types/actions/Common.action";

interface LocationUserProfileProps {
  selectedMenu: MENU;
  providerRoles: any;
  coAdminUserInputs: CoAdminUserModel;
  errorsCoAdminUser: ErrorsCoAdminUserModel;
  validateErrorCoAdminUser: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  handleCoAdminUserChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  /** Secondary users props */
  addSecondaryUser: boolean;
  setSecondaryUserInputs: any;
  secondaryUserInputs: LocationSecondaryUserModel;
  errorsSecondaryUser: ErrorsLocationSecondaryUserModel;
  validateErrorSecondaryUser: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  validateFormSecondaryUser: () => boolean;
  handleSecondaryUserChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  secondaryUsers: LocationSecondaryUserModel[];
  setSecondaryUsers: (e: LocationSecondaryUserModel[]) => void;
  handleResetSecondaryUser: () => void;
}

export const LocationUserProfile = ({
  selectedMenu,
  providerRoles,
  coAdminUserInputs,
  errorsCoAdminUser,
  validateErrorCoAdminUser,
  handleCoAdminUserChange,
  addSecondaryUser,
  setSecondaryUserInputs,
  secondaryUserInputs,
  errorsSecondaryUser,
  validateErrorSecondaryUser,
  validateFormSecondaryUser,
  handleSecondaryUserChange,
  secondaryUsers,
  setSecondaryUsers,
  handleResetSecondaryUser,
}: LocationUserProfileProps): ReactElement => {
  return (
    <>
      <div>
        <div className="outer__right-user-info">
          <h1 className="outer__right-user-info-heading">User Info</h1>
        </div>
      </div>
      <div>
        <CoAdminUser
          selectedMenu={selectedMenu}
          providerRoles={providerRoles}
          coAdminUserInputs={coAdminUserInputs}
          errorsCoAdminUser={errorsCoAdminUser}
          validateErrorCoAdminUser={validateErrorCoAdminUser}
          handleCoAdminUserChange={handleCoAdminUserChange}
        />
      </div>

      <div className="mt-[30px]">
        <LocationSecondaryUser
          providerRoles={providerRoles}
          addSecondaryUser={addSecondaryUser}
          setSecondaryUserInputs={setSecondaryUserInputs}
          secondaryUserInputs={secondaryUserInputs}
          errorsSecondaryUser={errorsSecondaryUser}
          validateErrorSecondaryUser={validateErrorSecondaryUser}
          validateFormSecondaryUser={validateFormSecondaryUser}
          handleSecondaryUserChange={handleSecondaryUserChange}
          secondaryUsers={secondaryUsers}
          setSecondaryUsers={setSecondaryUsers}
          handleResetSecondaryUser={handleResetSecondaryUser}
        />
      </div>
    </>
  );
};
