const states = require("./states").states;
export default class Util {
  static convertStringToArrayBufferView(str) {
    var bytes = new Uint8Array(str.length);
    for (var iii = 0; iii < str.length; iii++) bytes[iii] = str.charCodeAt(iii);
    return bytes;
  }

  static convertArrayBufferViewtoString(buffer) {
    var str = "";
    for (var iii = 0; iii < buffer.byteLength; iii++) str += String.fromCharCode(buffer[iii]);
    return str;
  }
  static capitalizeFirstLetter(str) {
    return str
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  }
  static getCities(state) {
    // state = state.trim().toUpperCase();
    if (!states[state]) {
      return [];
    } else {
      // const cities = states[state].cities.map((city) => {
      //   return Util.capitalizeFirstLetter(city);
      // });
      return states[state];
    }
  }

  //Get all U.S. states
  static getStates() {
    if (states) {
      return Object.keys(states);
      // const stateNames = Object.keys(states).map((state) => {
      //   return Util.capitalizeFirstLetter(state);
      // });
      // return stateNames;
    } else {
      return [];
    }
  }
}
