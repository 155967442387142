import { AppActions } from "../../../types";
import { CREATE_PROFILE } from "../../../types/actions/Provider/CreateProfile.action";
import { CreateProfileModel } from "../../../types/models/Provider/createProfile/CreateProfile";

const initialState: {
  CreateProfilData: CreateProfileModel;
} = {
  CreateProfilData: {
    signupData: { firstName: "", lastName: "", email: "", password: "", confirm_password: "", conditionCheck: false, verificationCode: 0 },
    primaryUser: { firstName: "", lastName: "", email: "", phone: "", role: "" },
    businessInfo: {
      businessName: "",
      businessPhone: "",
      businessAddress: "",
      businessEmail: "",
      businessCity: "",
      businessLocationType: "",
      businessState: "",
      businessZip: "",
      uploadStateLicenceOrURL: "",
    },
    secondaryUsers: [{ firstName: "", lastName: "", email: "", phone: "", role: "", password: "", confirm_password: "", permissions: [] }],
  },
};
const CreateProfile = (state = initialState, action: AppActions) => {
  switch (action.type) {
    case CREATE_PROFILE:
      return {
        ...state,
        CreateProfilData: action.payload,
      };

    default:
      return state;
  }
};
export default CreateProfile;
