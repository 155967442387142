import React, { ReactElement } from "react";
import { VAButton, DashboardCard } from "../../../components";
import { FaTimes } from "react-icons/fa";

interface TestVaccineCardsProps {
  selectedVaccine: any;
  setOpen: any;
  setModalVaccineId: any;
  age_less: any;
  age_greater: any;
  page: any;
  setRemoveModalOpen: any;
  setScreeningId: any;
  handleCancel: any;
}

export const TestVaccineCards = ({
  selectedVaccine,
  setOpen,
  setModalVaccineId,
  age_less,
  age_greater,
  page,
  setRemoveModalOpen,
  setScreeningId,
  handleCancel,
}: TestVaccineCardsProps): ReactElement => {
  return (
    <React.Fragment>
      {selectedVaccine.map((vaccine: any) => (
        <div className="flex flex-col gap-[20px]" style={{ marginTop: "20px" }}>
          <DashboardCard>
            <div className="w-[full] flex gap-[12px] justify-between items-center bg-[#b7cbd6] border-b-[1px] border-[ #0F5175] px-[20px] py-[12px] rounded-t-[12px]">
              {page === "add test" ? (
                <>
                  <h2 className="text-[#114059] text-[18px] font-[600]">
                    {vaccine.vaccine_name}
                    <span className="text-[16px] font-[400] text-[#1F2124]">
                      (Age group : Current age is {age_greater} years or older and {age_less} years or younger)
                    </span>
                  </h2>
                </>
              ) : (
                <React.Fragment>
                  <h2 className="text-[#114059] text-[18px] font-[600]">
                    {vaccine.vaccine_name}
                    <span className="text-[16px] font-[400] text-[#1F2124]">
                      (Age group : Current age is {vaccine.v_age_greater} years or older and {vaccine.v_age_less} years or younger)
                    </span>
                  </h2>
                  {/* delete btn here */}
                  {page !== "Test and paperwork" && (
                    <button className="btn btn-danger" style={{ padding: "10px", margin: "10px" }}>
                      <FaTimes
                        onClick={() => {
                          setModalVaccineId(vaccine.vaccine_id);
                          handleCancel();
                        }}
                      />
                    </button>
                  )}
                </React.Fragment>
              )}
            </div>

            <div className="bg-[#FFFFFF] p-[20px] rounded-b-[12px]">
              <div className="flex items-center justify-between border-b-[1px] border-[ #0F5175] pb-[9px]">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h2 className="text-[20px] font-[600] text-[#000000]">Screening questions</h2>
                </div>

                {page === "add test" ? (
                  <VAButton
                    outlined
                    text="View Forms"
                    onClick={() => {
                      setOpen(true);
                      setModalVaccineId(vaccine.vaccine_id);
                    }}
                  />
                ) : (
                  <VAButton
                    outlined
                    text="View Additional"
                    onClick={() => {
                      setOpen(true);
                      setModalVaccineId(vaccine.vaccine_id);
                    }}
                  />
                )}
              </div>
              {page === "add test"
                ? vaccine.screeningQuestions.map((item: any) =>
                    item.isChecked === true ? (
                      <div className="flex justify-between items-center border-b-[1px] border-[ #0F5175] ">
                        <p className="text-[16px] font-[400] text-[#1F2124] py-[12px]">{item.screening_name}</p>
                        <button
                          className="text-[#114059] text-[18px] font-[600] mt-[18px] cursor-pointer"
                          onClick={() => {
                            setRemoveModalOpen(true);
                            setModalVaccineId(vaccine.vaccine_id);
                            setScreeningId(item.screening_id);
                          }}
                        >
                          Remove
                        </button>
                      </div>
                    ) : null
                  )
                : vaccine.screening.map((item: any) =>
                    item.isChecked === true ? (
                      <div className="flex justify-between items-center border-b-[1px] border-[ #0F5175] ">
                        <p className="text-[16px] font-[400] text-[#1F2124] py-[12px]">{item.screening_name}</p>
                        <button
                          className="text-[#114059] text-[18px] font-[600] mt-[18px] cursor-pointer"
                          onClick={() => {
                            setRemoveModalOpen(true);
                            setModalVaccineId(vaccine.vaccine_id);
                            setScreeningId(item.screening_id);
                          }}
                        >
                          Remove
                        </button>
                      </div>
                    ) : null
                  )}

              <div className="flex items-center justify-between border-b-[1px] border-[ #0F5175] py-[18px]">
                <h2 className="text-[20px] font-[600] text-[#000000]">Consent Form</h2>
              </div>
              <div className="flex justify-between items-center border-b-[1px] border-[ #0F5175] ">
                <p className="text-[16px] font-[400] text-[#1F2124] py-[12px]">{vaccine.vc_title}</p>
              </div>
            </div>
          </DashboardCard>
        </div>
      ))}
    </React.Fragment>
  );
};
