import React, { useEffect, useState } from "react";
import { DashboardLayout, FormInput, VAButton } from "../../../components";
import { AlertType } from "../../../shared/constants/AppConst";
import VAAlert from "../../../components/Alert/Alert";
import { addCategory, deleteCategory, getCategories, updateCategory } from "../../../redux/actionCreators/SuperAdmin/Category";
import { MENU } from "../../../types/actions/Common.action";
import CategoriesTable from "./CategoriesTable";
import { Category } from "../../../sections/Superadmin";

// Usage example in a React component
export const Categories: React.FC = () => {
  const [categories, setCategories] = useState<any>([]);
  const [categoryTypes, setCategoryTypes] = useState<any>([]);
  const [categoryType, setCategoryType] = useState("");
  const [name, setName] = useState("");
  const [note, setNote] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [errorFields, setErrorFields] = useState({ name: { error: false, message: "" }, categoryType: { error: false, message: "" } });
  const [alertMessage, setAlertMessage] = useState<AlertType | null>(null);
  const [selectedMenu, setSelectedMenu] = useState<MENU>("VIEW");
  const handleChange = (event: any) => {
    if (event.target.name === "name") {
      setName(event.target.value as string);
    }
    if (event.target.name === "note") {
      setNote(event.target.value as string);
    }
    if (event.target.name === "category") {
      setSearchCategory(event.target.value as string);
    }
    if (event.target.name === "categoryType") {
      setCategoryType(event.target.value as string);
    }
  };
  const onBlurField = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    if (!value) {
      setErrorFields({ ...errorFields, [name]: { error: true, message: "Required Field" } });
    } else {
      setErrorFields({ ...errorFields, [name]: { error: false, message: "" } });
    }
  };

  const validateForm = () => {
    let formIsValid = true;
    const errorFields = { name: { error: false, message: "" }, categoryType: { error: false, message: "" } };
    if (!name) {
      errorFields.name.error = true;
      errorFields.name.message = "Required Field";
      formIsValid = false;
    }
    if (!categoryType) {
      errorFields.categoryType.error = true;
      errorFields.categoryType.message = "Required Field";
      formIsValid = false;
    }
    setErrorFields(errorFields);
    return formIsValid;
  };
  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        let res = selectedMenu === "ADD" ? await addCategory({ name, type: categoryType, note }) : await updateCategory({ category_id: categoryId, name, type: categoryType, note });
        setAlertMessage({ type: "success", description: res.message, open: true });
        setCategories(res.categories);
        setCategoryTypes(res.category_types);
        setSelectedMenu("VIEW");
      } catch (err) {
        setAlertMessage({ type: "error", description: "Somthing wen wrong", open: true });
        console.log(err);
      }
    }
  };
  const onClickAdd = () => {
    setSelectedMenu("ADD");
    setName("");
    setCategoryType("");
    setNote("");
  };
  const onClickEdit = (rowData: any) => {
    setSelectedMenu("EDIT");
    setCategoryId(rowData.id);
    setName(rowData.name);
    setCategoryType(rowData.categoryType);
    setNote(rowData.note);
  };
  const onClickDelete = async (category_id: any) => {
    if (window.confirm("Are you sure, you want to delete this record?")) {
      try {
        let res = await deleteCategory({ category_id });
        setAlertMessage({ type: "success", description: res.message, open: true });
        setCategories(res.categories);
        setCategoryTypes(res.category_types);
        setSelectedMenu("VIEW");
      } catch (err) {
        setAlertMessage({ type: "error", description: "Somthing wen wrong", open: true });
        console.log(err);
      }
    }
  };
  const onClickBack = () => {
    setSelectedMenu("VIEW");
  };
  const getCategoriesData = async () => {
    try {
      let res = await getCategories();
      setCategories(res.categories);
      setCategoryTypes(res.category_types);
      setSelectedMenu("VIEW");
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getCategoriesData();
  }, []);
  const filteredCategories = categories.filter((category: any) => category.name.toLowerCase().includes(searchCategory.toLowerCase()));
  const renderMenu = () => {
    switch (selectedMenu) {
      case "VIEW":
        return (
          <div>
            <div>
              <h2 className="text-[24px] text-primary-main font-[600]">Vaccine Categories</h2>
            </div>
            <div className="flex items-center justify-between py-[20px]">
              <FormInput label="Search for name 🔍" name="category" value={searchCategory} onChange={handleChange} />
              <VAButton dark text="Add New" onClick={onClickAdd} />
            </div>
            <div className="mt-[30px]">
              <CategoriesTable categories={filteredCategories} onClickEdit={onClickEdit} onClickDelete={onClickDelete} />
            </div>
          </div>
        );
      case "ADD":
      case "EDIT":
        return (
          <div>
            <div>
              <h2 className="text-[20px] font-[600] text-[#0F5175]">{selectedMenu === "ADD" ? "Add New Category" : "Update Category"}</h2>
              <p className="text-[14px] font-[400] text-[rgba(50,53,57,0.4)]">Enter Information</p>
            </div>
            <div className="mt-[30px]">
              <Category
                selectedMenu={selectedMenu}
                name={name}
                categoryType={categoryType}
                categoryTypes={categoryTypes}
                note={note}
                handleChange={handleChange}
                onBlurField={onBlurField}
                errorFields={errorFields}
                handleSubmit={handleSubmit}
                onClickBack={onClickBack}
              />
            </div>
          </div>
        );

      default:
        break;
    }
  };
  return (
    <DashboardLayout>
      {alertMessage !== null ? <VAAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} /> : null}
      {renderMenu()}
    </DashboardLayout>
  );
};
