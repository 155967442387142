import { ReactElement, Fragment } from "react";
import { Box, Modal, Stack } from "@mui/material";
import { VAButton } from "../VAButton/VAButton.component";

const style = { width: "90%", bgcolor: "background.paper", boxShadow: 24, borderRadius: "20px", p: 4, marginTop: "10px" };

interface ViewVerifiedDataModalProps {
  data: any;
  open: boolean;
  setOpen: (a: boolean) => void;
  onClickEditVerifiedData: (data: any) => void;
}

export const ViewVerifiedDataModal = ({ data, open = false, setOpen, onClickEditVerifiedData }: ViewVerifiedDataModalProps): ReactElement => {
  const TestContent = () => {
    return (
      <Fragment>
        <div className="mt-[10px]">
          <p className="text-[#888A8C] flex justify-between">
            <span>Test:</span>
            <span className="text-[#000000]">{data.vaccine_name}</span>
          </p>
          <p className="text-[#888A8C] flex justify-between">
            <span>Manufacturer:</span>
            <span className="text-[#000000]">{data.manufacturer}</span>
          </p>
          <p className="text-[#888A8C] flex justify-between">
            <span>Patient:</span>
            <span className="text-[#000000]">{data.patient_name}</span>
          </p>
          <p className="text-[#888A8C] flex justify-between">
            <span>Verified By:</span>
            <span className="text-[#000000]">{data.verified_by}</span>
          </p>
          <p className="text-[#888A8C] flex justify-between">
            <span>Specimen Source:</span>
            <span className="text-[#000000]">{data.specimen_source}</span>
          </p>
          <p className="text-[#888A8C] flex justify-between">
            <span>Test Type:</span>
            <span className="text-[#000000]">{data.test_type}</span>
          </p>
          {data.test_type === "Categorical" && (
            <p className="text-[#888A8C] flex justify-between">
              <span>Result:</span>
              <span className="text-[#000000]">{data.categorical_result}</span>
            </p>
          )}
          {data.test_type === "Numerical" && (
            <p className="text-[#888A8C] flex justify-between">
              <span>Result:</span>
              <span className="text-[#000000]">{`${data.numerical_value} ${data.numerical_result}`}</span>
            </p>
          )}
          <p className="text-[#888A8C] flex justify-between">
            <span>Test Date:</span>
            <span className="text-[#000000]">{data.test_date}</span>
          </p>
          <p className="text-[#888A8C] flex justify-between">
            <span>Test Date Resulted:</span>
            <span className="text-[#000000]">{data.test_date_resulted}</span>
          </p>
          <p className="text-[#888A8C] flex justify-between">
            <span>Notes:</span>
            <span className="text-[#000000]">{data.note}</span>
          </p>
        </div>
      </Fragment>
    );
  };

  const VaccineContent = () => {
    return (
      <Fragment>
        <div className="mt-[10px]">
          <p className="text-[#888A8C] flex justify-between">
            <span>Vaccine:</span>
            <span className="text-[#000000]">{data.vaccine_name}</span>
          </p>
          <p className="text-[#888A8C] flex justify-between">
            <span>Manufacturer:</span>
            <span className="text-[#000000]">{data.manufacturer}</span>
          </p>
          <p className="text-[#888A8C] flex justify-between">
            <span>Patient:</span>
            <span className="text-[#000000]">{data.patient_name}</span>
          </p>
          <p className="text-[#888A8C] flex justify-between">
            <span>Verified By:</span>
            <span className="text-[#000000]">{data.verified_by}</span>
          </p>
          <p className="text-[#888A8C] flex justify-between">
            <span>LOT No:</span>
            <span className="text-[#000000]">{data.lot_no}</span>
          </p>
          <p className="text-[#888A8C] flex justify-between">
            <span>NDC:</span>
            <span className="text-[#000000]">{data.ndc}</span>
          </p>
          <p className="text-[#888A8C] flex justify-between">
            <span>Date Exp:</span>
            <span className="text-[#000000]">{data.date_exp}</span>
          </p>
          <p className="text-[#888A8C] flex justify-between">
            <span>Date Administered:</span>
            <span className="text-[#000000]">{data.date_administered}</span>
          </p>
          <p className="text-[#888A8C] flex justify-between">
            <span>Notes:</span>
            <span className="text-[#000000]">{data.note}</span>
          </p>
        </div>
      </Fragment>
    );
  };
  return (
    <div>
      <Modal
        open={open}
        style={{
          display: "flex",
          alignItems: "center",
          overflowY: "scroll",
          justifyContent: "center",
        }}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: "auto" }}>
          <div className="">
            <div className="flex justify-center">
              <h2 className="text-[16px] md:text-[20px] text-[#000000] font-[600]">View Verified Data </h2>
            </div>
            {data.vaccine_type === "TEST" ? TestContent() : VaccineContent()}

            <div className="mt-[10px] flex justify-center cursor-pointer" onClick={() => setOpen(false)}>
              <Stack direction="row" alignItems="start" justifyContent={"center"} spacing={2}>
                <VAButton size="small" outlined text="Close" onClick={() => setOpen(false)} />
                <VAButton size="small" dark text="Edit" onClick={() => onClickEditVerifiedData(data)} />
              </Stack>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
