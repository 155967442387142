import { ReactElement } from "react";
import { CalendarNext, CalendarPrev } from "../../icons";
import { VAButton } from "../VAButton/VAButton.component";

interface MOnthYearProps {
  month: any;
  year: any;
  handlePreviousMonth: any;
  handleNextMonth: any;
}
export const MonthAndYear = ({ month, year, handlePreviousMonth, handleNextMonth }: MOnthYearProps): ReactElement => {
  return (
    <div className="flex items-center mt-4 gap-[20px]">
      <h2 className="text-xl text-[#1F2124] font-bold leading-none">
        {month} {year}
      </h2>
      <div className="flex ml-2 text-[#0F5175]">
        <button onClick={handlePreviousMonth}>
          <CalendarPrev />
        </button>
        <button onClick={handleNextMonth}>
          <CalendarNext />
        </button>
      </div>
      <VAButton text="Today" outlined />
    </div>
  );
};
