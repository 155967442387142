import React from "react";
import { styled } from "@mui/material/styles";
import { Stack, TableCell, TableRow } from "@mui/material";
import { VAButton } from "../../../../components";

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: 14,
  padding: 8,
  "&:first-of-type": {
    paddingLeft: 20,
  },
  "&:last-of-type": {
    paddingRight: 20,
  },
}));

interface TableItemProps {
  data: any;
  onClickEdit: (rowData: any) => void;
  onClickDelete: (category_id: any) => void;
}

const TableItem: React.FC<TableItemProps> = ({ data, onClickEdit, onClickDelete }) => {
  const actionCell = () => {
    return (
      <Stack direction="row" alignItems="start" justifyContent={"center"} spacing={2}>
        <VAButton size="small" dark text="Edit" onClick={() => onClickEdit(data)} />
        <VAButton size="small" light text="Delete" onClick={() => onClickDelete(data.id)} />
      </Stack>
    );
  };

  return (
    <>
      <TableRow key={data.id} className="item-hover">
        <StyledTableCell align="left">{data.name}</StyledTableCell>
        <StyledTableCell align="left">{data.type}</StyledTableCell>
        <StyledTableCell align="left">{data.note}</StyledTableCell>
        {!data.verify ? <StyledTableCell align="center">{actionCell()}</StyledTableCell> : null}
      </TableRow>
    </>
  );
};

export default TableItem;
